<template>
  <div class="edit-reservation">
    <h1 class="admin-title">{{$t('Edit Reservation')}}</h1>
    <ValidationObserver ref="validator">
      <form @submit.prevent>
        <b-row>
          <b-col>
            <RFInput label="Status" v-model="reservation.status" readonly />
          </b-col>
          <b-col>
            <RFValidation name="Start Date" rules="required">
              <RFMonthPicker label="Start Date" v-model="reservation.startDate" :special-format="specialDateFormat"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Expiring Date" rules="required">
              <RFMonthPicker label="Expiring Date" v-model="reservation.expiringDate" :ending-date="true" :special-format="specialDateFormat"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <RFValidation name="Contract number" rules="required">
              <RFInput label="Contract number" v-model="reservation.numeroContratto" readonly/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFCheckbox class="mt-4" label="Contract accepted" v-model="reservation.contractAccepted" :readonly="isActive || isExpired"/>
          </b-col>
        </b-row>

        <h3 class="admin-subtitle mt-5">{{$t('Amounts')}}</h3>

        <b-row>
          <b-col>
            <RFValidation name="Monthly Amount" rules="required|positiveNumber" v-if="!isExpired">
              <RFCurrencyInput label="Monthly Amount" :key="monthlyUpdate" v-model="reservation.monthlyAmount" />
            </RFValidation>
            <RFInput label="Deposit Amount" v-model="reservation.monthlyAmount" readonly v-else/>
          </b-col>
          <b-col>
            <RFValidation name="Deposit Amount" rules="required|positiveNumber" v-if="!isActive && !isExpired">
              <RFCurrencyInput label="Deposit Amount" :key="depositUpdate" v-model="reservation.depositAmount"/>
            </RFValidation>
            <RFInput label="Deposit Amount" v-model="reservation.depositAmount" readonly v-else/>
          </b-col>
          <b-col>
            <RFValidation name="Service Fees" rules="required|positiveNumber" v-if="!isActive && !isExpired">
              <RFCurrencyInput label="Service Fees" :key="serviceUpdate" v-model="reservation.serviceFees"/>
            </RFValidation>
            <RFInput label="Service Fees" v-model="reservation.serviceFees" readonly v-else/>
          </b-col>
        </b-row>
        <div class="warning mt-2">{{$t('When save, a new contract will be generated, deleting the old one')}}</div>

        <div v-if="reservation.room">
          <h3 class="admin-subtitle mt-5">{{$t('Room')}}</h3>
          <div class="mt-2 data">
            <b>{{reservation.room.name}}</b><br>
            {{reservation.buildingName}} ⸱ {{reservation.room.type.name}} ⸱ {{reservation.room.adminComment}} ⸱ {{$t('Floor')}} {{reservation.room.floor}}
          </div>
          <RFButton class="mt-3" label="Change room" background="#24425B" @on-click="openModal" :readonly="isExpired"/>
        </div>

        <div v-if="reservation.apartment">
          <h3 class="admin-subtitle mt-5">{{$t('Apartment')}}</h3>
          <div class="mt-2 data">
            <b>{{reservation.apartment.name}}</b><br>
            {{reservation.apartment.type.name}} ⸱ {{reservation.apartment.adminComment}} ⸱ {{$t('Floor')}} {{reservation.apartment.floor}}
          </div>
          <RFButton class="mt-3" label="Change Apartment" background="#24425B" @on-click="openModal" :readonly="isExpired"/>
        </div>

        <ReservationEditAccommodationModal :type="modalType" @change-room="changeRoom" @change-apartment="changeApartment"/>

        <b-row class="justify-content-end mt-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" @on-click="save" v-if="!loading"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFValidation from '@/components/forms/RFValidation'
import RFInput from '@/components/forms/RFInput'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFMonthPicker from '@/components/forms/RFMonthPicker'
import RFCheckbox from '@/components/forms/RFCheckbox'
import ReservationEditAccommodationModal from "@/components/admin/reservations/ReservationEditAccommodationModal";

import { reservationService } from '@/services/admin'
import { accommodations, reservationStatus } from '@/enums'
import { mapState } from 'vuex'

export default {
  name: 'AdminEditReservation',
  components: {
    RFValidation,
    RFInput,
    RFCurrencyInput,
    RFButton,
    RFLoader,
    RFMonthPicker,
    RFCheckbox,
    ReservationEditAccommodationModal
  },
  data() {
    return {
      accommodations,
      reservation: null,
      loading: false,
      specialDateFormat: true,
      modalType: null,
      monthlyUpdate: false,
      depositUpdate: false,
      serviceUpdate: false,
    }
  },
  computed: {
    ...mapState({
      storedReservation: state => state.adminReservationStore.reservation,
    }),
    isActive() {
      return this.reservation.status === reservationStatus.ACTIVE
    },
    isExpired(){
      return this.reservation.status === reservationStatus.EXPIRED
    }
  },
  beforeMount() {
    this.reservation = { ...this.storedReservation }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true

        const { data: updatedReservation, status } = await reservationService.updateReservation(this.reservation)

        if (status === 200) {
          this.reservation = { ...updatedReservation }
          this.$toasted.success(this.$i18n.t(`Reservation successfully edited!`))
        } else {
          let errorMessage = "Error during edit reservation"
          if(status===400 && updatedReservation.message!==undefined){
            errorMessage = errorMessage+": "+updatedReservation.message
          }
          this.$toasted.error(this.$i18n.t(`${errorMessage}`))
        }

        this.loading = false
      }
    },
    openModal() {
      this.$store.commit('adminStore/setReservation', this.reservation)
      this.monthlyUpdate = false
      this.depositUpdate = false
      this.serviceUpdate = false
      if(this.reservation && this.reservation.room){
        this.modalType = accommodations.ROOM;
      }
      else if(this.reservation){
        this.modalType = accommodations.APARTMENT;
      }
      this.$root.$emit('bv::show::modal', 'reservation-edit-accommodation-modal')
    },
    changeRoom(room) {
      this.reservation.room = room
      this.reservation.roomType = room.type
      this.reservation.monthlyAmount = room.monthlyAmount
      this.reservation.buildingId = room.buildingId
      this.reservation.buildingName = room.buildingName
      this.modalType = null
      this.monthlyUpdate = true
      if(!this.isActive){
        this.reservation.depositAmount = room.depositAmount
        this.reservation.serviceFees = room.serviceFees
        this.depositUpdate = true
        this.serviceUpdate = true
      }
    },
    changeApartment(apartment) {
      this.reservation.apartment = apartment
      this.reservation.monthlyAmount = apartment.monthlyAmount
      this.modalType = null
      this.monthlyUpdate = true
      if(!this.isActive){
        this.reservation.depositAmount = apartment.depositAmount
        this.reservation.serviceFees = apartment.serviceFees
        this.depositUpdate = true
        this.serviceUpdate = true
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.warning {
  @include font-style($montserrat, 'medium', $font-16);
  color: $dark-blue;
}
</style>
