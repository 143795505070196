<template>
  <RFModal :modalId="modalId" v-if="reservation"
           :title="type === accommodations.ROOM ? `${reservation.buildingName} - ${reservation.roomType.name}` : reservation.apartment.name"
           @send="accept" @cancel="cancel" :loading="loading" ok-label="Accept" cancel-label="Cancel" has-cancel @hidden="hideModal">
    <b-row class="reservation-data">
      <b-col class="reservation-label">
        {{`${$t('Reservation N°')}: ${reservation.id}`}}
      </b-col>
      <b-col class="reservation-label">
        {{reservation.startDate | extractMonthLocaleFromDate}} {{reservation.startDate | extractYearFromDate}} - {{reservation.expiringDate | extractMonthLocaleFromDate}} {{reservation.expiringDate | extractYearFromDate}}
      </b-col>
    </b-row>
    <div class="guests">
      <b-row class="mt-4 mb-3">
        <b-col class="guests-fields">{{$t('Guest')}}</b-col>
        <b-col class="guests-fields">{{$t('Nationality')}}</b-col>
        <b-col class="guests-fields">{{$t('Birth Date')}}</b-col>
        <b-col class="guests-fields">{{$t('Account Holder')}}</b-col>
      </b-row>
      <b-row class="guest-data mb-3" v-for="(guest, index) in guests" :key="index">
        <b-col class="guest-label">{{`${guest.firstname} ${guest.lastname}`}}</b-col>
        <b-col class="guest-label">{{guest.nationality}}</b-col>
        <b-col class="guest-label">{{guest.birthDate | formatDate}}</b-col>
        <b-col class="guest-label">
          <FontAwesomeIcon color="#5CB85C" v-if="guest.isPrimary"
                           :icon="['fas', 'check-circle']"/>
        </b-col>
      </b-row>
    </div>
    <div class="mt-5 ml-2" v-if="searchLoading && type === accommodations.ROOM">
      <RFLoader class="mt-3" color="#24425B" :size="40"/>{{$t('Uploading...')}}
    </div>
    <div class="rooms" v-if="type === accommodations.ROOM && !searchLoading">
      <div class="available-title">{{$t('Available Rooms')}}</div>
      <b-row>
        <b-col class="rooms-fields">{{$t('Building')}}</b-col>
        <b-col class="rooms-fields">{{$t('Name')}}</b-col>
        <b-col class="rooms-fields">{{$t('Type')}}</b-col>
        <b-col class="rooms-fields">{{$t('Admin Comment')}}</b-col>
        <b-col class="rooms-fields">{{$t('Floor')}}</b-col>
        <b-col class="rooms-fields">{{$t('Select')}}</b-col>
      </b-row>
      <RFLoader class="mt-3" color="#24425B" :size="30" v-if="this.searchLoading"/>
      <b-row class="room" :class="{ 'selected': selectedRoom && room.id === selectedRoom.id }" v-for="(room, index) in rooms" :key="index" @click="selectedRoom = room">
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="room.buildingName">{{room.buildingName}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="room.name">{{room.name}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label type" v-if="room.type.name">{{room.type.name}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="room.adminComment">{{room.adminComment}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="room.floor">{{room.floor}}</div>
        </b-col>
        <b-col class="room-detail">
          <transition name="fade">
            <div class="selected-room" v-if="selectedRoom && room.id === selectedRoom.id">
              <FontAwesomeIcon color="#5CB85C" :icon="['fa', 'check-circle']"/>
            </div>
          </transition>
        </b-col>
      </b-row>
      <div class="occupied-rooms mt-5" v-if="occupiedRooms.length">
        <div class="occupied-title">{{$t('Occupied Rooms')}}</div>
        <b-row>
          <b-col class="occupied-fields">{{$t('Building')}}</b-col>
          <b-col class="occupied-fields">{{$t('Name')}}</b-col>
          <b-col class="occupied-fields">{{$t('Type')}}</b-col>
          <b-col class="occupied-fields">{{$t('Admin Comment')}}</b-col>
          <b-col class="occupied-fields">{{$t('Floor')}}</b-col>
          <b-col class="occupied-fields">{{$t('View guests')}}</b-col>
        </b-row>
        <b-row class="room" v-for="(occupiedRoom, index) in occupiedRooms" :key="`occupied-${index}`">
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupiedRoom.buildingName">{{occupiedRoom.buildingName}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupiedRoom.room.name">{{occupiedRoom.room.name}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label type" v-if="occupiedRoom.room.type">{{occupiedRoom.room.type.name}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupiedRoom.room.adminComment">{{occupiedRoom.room.adminComment}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupiedRoom.room.floor">{{occupiedRoom.room.floor}}</div>
          </b-col>
          <b-col class="room-detail">
            <FontAwesomeIcon v-if="index !== selectedOccupiedRoom" class="cursor-pointer" :icon="['fas', 'plus']" @click="selectOccupiedRoom(index)"/>
            <FontAwesomeIcon v-else class="cursor-pointer" :icon="['fas', 'minus']" @click="selectOccupiedRoom(index)"/>
          </b-col>
          <b-col cols="12" class="occupied-room-guests" :class="{ 'open': index === selectedOccupiedRoom }">
            <b-row class="occupied-room-row align-items-center" v-for="(guest, index) in occupiedRoom.guests" :key="`guest-${index}`">
              <b-col cols="auto" class="occupied-room-guest">
                <FontAwesomeIcon :icon="['fas', 'user']" />
              </b-col>
              <b-col class="occupied-room-guest">
                {{`${guest.firstname} ${guest.lastname}`}}
              </b-col>
              <b-col class="occupied-room-guest">
                {{guest.birthDate | formatDate}}
              </b-col>
              <b-col class="occupied-room-guest">
                {{guest.genre}}
              </b-col>
              <b-col class="occupied-room-guest">
                {{guest.nationality}}
              </b-col>
              <b-col class="occupied-room-guest" v-if="guest.fiscalCode">
                {{guest.fiscalCode}}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="mt-5 ml-2" v-if="searchLoading && type === accommodations.APARTMENT">
      <RFLoader class="mt-3" color="#24425B" :size="40"/>{{$t('Uploading...')}}
    </div>
    <div class="rooms" v-if="type === accommodations.APARTMENT && !searchLoading">
      <div class="available-title">{{$t('Available Apartments')}}</div>
      <b-row>
        <b-col class="rooms-fields">{{$t('Name')}}</b-col>
        <b-col class="rooms-fields">{{$t('Type')}}</b-col>
        <b-col class="rooms-fields">{{$t('Admin Comment')}}</b-col>
        <b-col class="rooms-fields">{{$t('Floor')}}</b-col>
        <b-col class="rooms-fields">{{$t('Select')}}</b-col>
      </b-row>
      <b-row class="room" :class="{ 'selected': selectedApartment && apartment.id === selectedApartment.id }" v-for="(apartment, index) in apartments" :key="index" @click="selectedApartment = apartment">
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="apartment.name">{{apartment.name}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label type" v-if="apartment.type.name">{{apartment.type.name}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="apartment.adminComment">{{apartment.adminComment}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="apartment.floor">{{apartment.floor}}</div>
        </b-col>
        <b-col class="room-detail">
          <transition name="fade">
            <div class="selected-room" v-if="selectedApartment && apartment.id === selectedApartment.id">
              <FontAwesomeIcon color="#5CB85C" :icon="['fa', 'check-circle']"/>
            </div>
          </transition>
        </b-col>
      </b-row>
      <div class="occupied-rooms mt-5" v-if="occupiedApartments.length">
        <div class="occupied-title">{{$t('Occupied Apartments')}}</div>
        <b-row>
          <b-col class="occupied-fields">{{$t('Name')}}</b-col>
          <b-col class="occupied-fields">{{$t('Type')}}</b-col>
          <b-col class="occupied-fields">{{$t('Admin Comment')}}</b-col>
          <b-col class="occupied-fields">{{$t('Floor')}}</b-col>
          <b-col class="occupied-fields">{{$t('View guests')}}</b-col>
        </b-row>
        <b-row class="room" v-for="(occupiedApartment, index) in occupiedApartments" :key="`occupied-${index}`">
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupiedApartment.apartment.name">{{occupiedApartment.apartment.name}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label type" v-if="occupiedApartment.apartment.type">{{occupiedApartment.apartment.type.name}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupiedApartment.apartment.adminComment">{{occupiedApartment.apartment.adminComment}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupiedApartment.apartment.floor">{{occupiedApartment.apartment.floor}}</div>
          </b-col>
          <b-col class="room-detail">
            <FontAwesomeIcon v-if="index !== selectedOccupiedApartment" class="cursor-pointer" :icon="['fas', 'plus']" @click="selectOccupiedApartment(index)"/>
            <FontAwesomeIcon v-else class="cursor-pointer" :icon="['fas', 'minus']" @click="selectOccupiedApartment(index)"/>
          </b-col>
          <b-col cols="12" class="occupied-room-guests" :class="{ 'open': index === selectedOccupiedApartment }">
            <b-row class="occupied-room-row align-items-center" v-for="(guest, index) in occupiedApartment.guests" :key="`guest-${index}`">
              <b-col cols="auto" class="occupied-room-guest">
                <FontAwesomeIcon :icon="['fas', 'user']" />
              </b-col>
              <b-col class="occupied-room-guest">
                {{`${guest.firstname} ${guest.lastname}`}}
              </b-col>
              <b-col class="occupied-room-guest">
                {{guest.birthDate | formatDate}}
              </b-col>
              <b-col class="occupied-room-guest">
                {{guest.genre}}
              </b-col>
              <b-col class="occupied-room-guest">
                {{guest.nationality}}
              </b-col>
              <b-col class="occupied-room-guest" v-if="guest.fiscalCode">
                {{guest.fiscalCode}}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="warning" v-if="slotsOccupied">
      <b-row class="mt-4 mb-3">
        <b-col class="warning-fields">WARNING: Some calendar slots may be occupied by another reservation</b-col>
      </b-row>
    </div>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import { adminService, apartmentService,buildingService } from '@/services/admin'
import { accommodations, reservationStatus } from '@/enums'
import { mapState } from 'vuex'
import RFLoader from "@/components/forms/RFLoader";

export default {
  name: 'ReservationEditAccommodationModal',
  components: {
    RFModal,
    RFLoader
  },
  props: {
    type: String,
  },
  data() {
    return {
      accommodations,
      reservationStatus,

      modalId: 'reservation-edit-accommodation-modal',
      rooms: [],
      apartments: [],
      occupiedRooms: [],
      occupiedApartments : [],
      selectedRoom: null,
      selectedApartment : null,
      guests: [],
      selectedOccupiedRoom: null,
      selectedOccupiedApartment: null,
      loading: false,
      searchLoading:false
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.adminStore.reservation
    }),
    reservationGuests() {
      return this.reservation ? this.reservation.guests : []
    },
    slotsOccupied(){
      let slotsOccupied = false
      if(this.reservation){
        let startDate = new Date(this.reservation.startDate)
        let expiringDate = new Date(this.reservation.expiringDate)
        if(this.selectedApartment !== null){
          slotsOccupied = this.selectedApartment.periods.some((calendarSlot) => (new Date(calendarSlot.date))>=startDate && (new Date(calendarSlot.date)<=expiringDate))
        }
        else if(this.selectedRoom !== null){
          slotsOccupied = this.selectedRoom.periods.some((calendarSlot) => (new Date(calendarSlot.date))>=startDate && (new Date(calendarSlot.date)<=expiringDate))
        }
      }
      return slotsOccupied
    }
  },
  beforeMount() {
    this.initReservationModal()
  },
  methods: {
    async initReservationModal() {
      if (this.reservation !== null) {
        this.setGuests()
        this.searchLoading = true
        if(this.type !== null) {
          if (this.type === accommodations.ROOM) {
            if (this.reservation !== null) {
              await this.getAvailableRooms()
              await this.getOccupiedRooms()
              this.searchLoading = false
            }
          } else {
            if (this.reservation !== null) {
              await this.getAvailableApartments()
              await this.getOccupiedApartments()
              this.searchLoading = false
            }
          }
        }
      }
    },
    async getAvailableRooms() {
      this.rooms = await buildingService.getActiveRoomsWithCapacity(this.guests.length,this.reservation.room.type.isShared);
    },
    async getAvailableApartments(){
      this.apartments = await apartmentService.getActiveApartmentsWithCapacity(this.guests.length);
    },
    async getOccupiedRooms() {
      this.occupiedRooms = await adminService.getOccupiedRooms(this.reservation.startDate)

      this.occupiedRooms.forEach(occupiedRoom => occupiedRoom.guests = [...occupiedRoom.guests, occupiedRoom.user])
    },
    async getOccupiedApartments(){
       this.occupiedApartments = await adminService.getOccupiedApartments(this.reservation.startDate)

      this.occupiedApartments.forEach(occupiedApartment => occupiedApartment.guests = [...occupiedApartment.guests, occupiedApartment.user])
    },
    selectOccupiedRoom(index) {
      if (this.selectedOccupiedRoom === index) {
        this.selectedOccupiedRoom = null
      } else {
        this.selectedOccupiedRoom = index
      }
    },
    selectOccupiedApartment(index) {
      if (this.selectedOccupiedApartment === index) {
        this.selectedOccupiedApartment = null
      } else {
        this.selectedOccupiedApartment = index
      }
    },
    setGuests() {
      if (this.reservation) {
        this.guests = []
        this.guests = this.reservation.guests
        this.reservation.user.isPrimary = true
        this.guests = [...this.guests, this.reservation.user]
        this.guests.reverse()
      }
    },
    async accept() {
      if (this.type === accommodations.ROOM && !this.selectedRoom) {
        this.$toasted.info(this.$i18n.t('Select a room'))
        return false
      }
      else if(this.type === accommodations.APARTMENT && !this.selectedApartment){
        this.$toasted.info(this.$i18n.t('Select an apartment'))
        return false
      }

      this.loading = true
      this.$root.$emit('bv::hide::modal', this.modalId)
      if (this.type === accommodations.ROOM) {
        this.$emit('change-room', this.selectedRoom)
      }
      else {
        this.$emit('change-apartment', this.selectedApartment)
      }
      this.loading = false
    },
    async cancel() {
      this.$root.$emit('bv::hide::modal', this.modalId)
    },
    hideModal(){
      if(this.type === accommodations.ROOM) {
        this.selectedRoom = null
      }
      else{
        this.selectedApartment = null;
      }
    }
  },
  watch: {
    reservation: {
      handler() {
        this.initReservationModal()
        // this.setGuests()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#reservation-edit-accommodation-modal {
  .reservation-title {
    @include font-style($montserrat, 'semibold', $font-20);
    text-transform: capitalize;
    color: $white;
    margin: 0;
  }
  .reservation-data {
    margin: 10px 0;

    .reservation-label {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $dark-blue;
      text-align: center;
    }
  }
  .guests {
    .guests-fields {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $dark-blue;
      text-align: center;
    }
    .guest-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $dark-blue;
      text-align: center;
    }
  }
  .rooms {
    margin-top: 50px;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;

    .available-title {
      @include font-style($montserrat, 'semibold', $font-20);
      color: $dark-blue;
      border-bottom: 1px solid $gray;
      margin: 0 40px 20px 5px;
    }
    .rooms-fields {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $dark-blue;
    }
    .room {
      padding: 10px;
      cursor: pointer;
      transition: background-color .3s;

      &:hover {
        background-color: rgba($gray, .5);
      }
      &.selected {
        background-color: $gray;
      }
      .room-detail {
        @include font-style($montserrat, 'medium', $font-14);
        display: flex;
        align-items: center;

        .room-detail-label {
          &.type {
            text-transform: capitalize;
          }
        }
      }
    }
    .occupied-rooms {
      .occupied-title {
        @include font-style($montserrat, 'semibold', $font-20);
        color: $dark-blue;
        border-bottom: 1px solid $gray;
        margin-bottom: 20px;
        margin: 0 40px 20px 5px;
      }
      .occupied-fields {
        @include font-style($montserrat-bold, 'bold', $font-16);
        color: $dark-blue;
      }
      .room {
        cursor: default;

        &:hover {
          background-color: $white;
        }
        .occupied-room-guests {
          background-color: rgba($gray, .5);
          border-bottom: .5px solid $black;
          padding: 0 50px;
          text-align: center;
          max-height: 0;
          overflow-y: hidden;
          transition: all .3s ease-in-out;

          &:last-child {
            border-bottom: none;
          }
          &.open {
            max-height: 1000px;
            overflow-y: visible;
          }
          .occupied-room-row {
            height: 50px;

            .occupied-room-guest {
              @include font-style($montserrat, 'medium', $font-14);
              color: $dark-blue;
            }
          }
        }
      }
    }
  }
  .apartment {
    margin-top: 30px;
    .apartment-infos {

    }
    .apartment-gmap {
      height: 200px;
    }
  }

  .warning {
    .warning-fields {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $danger;
      text-align: center;
    }

    .warning-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $danger;
      text-align: center;
    }
  }
}
</style>
